import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from 'components';
import { LoadingButton } from '@mui/lab';
import ItemsModal from '../ItemsModal';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  flexEnd: {
    marginLeft: 'auto'
  }
}));

const ItemsToolbar = props => {
  const {
    className,
    setToolbarOptions,
    toolbarOptions,
    search,
    loading,
    categories,
    ...rest
  } = props;

  const classes = useStyles();

  const handleChange = e => {
    setToolbarOptions({ ...toolbarOptions, [e.target.name]: e.target.value });
  };

  const [open, setOpen] = React.useState(false);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <ItemsModal
        open={open}
        state="create"
        handleClose={() => setOpen(false)}
        onSuccess={() => search({ page: 1, perPage: 25 })}
        categories={categories}
      />
      <form className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search items"
          name="search"
          onChange={handleChange}
          value={toolbarOptions.search}
        />
        <TextField
          style={{ marginLeft: '5px', marginRight: '5px' }}
          margin="dense"
          name="categoryId"
          label="Category"
          variant="outlined"
          select
          onChange={handleChange}
          SelectProps={{ native: true }}
          value={toolbarOptions.categoryId || ''}>
          <option value="all">
            All
          </option>
          {categories.map(category => {
            return (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            );
          })}
        </TextField>
        <TextField
          style={{ marginLeft: '5px', marginRight: '5px' }}
          margin="dense"
          name="weightClass"
          label="Weight Class"
          variant="outlined"
          select
          onChange={handleChange}
          SelectProps={{ native: true }}
          value={toolbarOptions.weightClass || 'all'}>
          <option value="all">All</option>
          <option value="large">Large</option>
          <option value="medium">Medium</option>
          <option value="small">Small</option>
        </TextField>
        <TextField
          style={{ marginLeft: '5px', marginRight: '5px' }}
          margin="dense"
          name="active"
          label="Active"
          variant="outlined"
          select
          onChange={handleChange}
          SelectProps={{ native: true }}
          value={toolbarOptions.active || 'all'}>
          <option value="all">All</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </TextField>
        <LoadingButton
          loading={loading}
          onClick={search}
          variant="outlined"
          style={{ marginLeft: '10px' }}>
          Search
        </LoadingButton>
        <div style={{ marginLeft: 'auto' }}>
          <LoadingButton
            onClick={() => setOpen(true)}
            loading={loading}
            variant="outlined">
            Add Item
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

ItemsToolbar.propTypes = {
  className: PropTypes.string
};

export default ItemsToolbar;
