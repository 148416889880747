import HttpInstance from './axios';

const GetPushNotifications = async ({ page = 1, perPage = 10 }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/prompts?page=${page}&per_page=${perPage}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CreatePushNotification = async ({ title, message, userType, scheduledDateTime }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/prompts', { title, message, user_type: userType, scheduled_date_time: scheduledDateTime });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const UpdatePushNotification = async ({ id, action }) => {
  try {
    const response = await HttpInstance.patch(`/api/v1/admin/prompts/${id}`, { action });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  GetPushNotifications,
  CreatePushNotification,
  UpdatePushNotification
};
