import React, { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ordersApi from 'apis/orders';
import moment from 'moment';
import {
  Grid,
  Typography,
  Link,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ImageList,
  ImageListItem,
  TextField
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import Modal from '@mui/material/Modal';
import { Card } from 'components';
import GoogleMapReact from 'google-map-react';
import toast, { Toaster } from 'react-hot-toast';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const CancelOrderModel = ({ open, handleClose, handleCancel, order }) => {
  const [reason, setReason] = useState('');

  const handleChange = event => {
    setReason(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Are you sure you want to cancel this order?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          This driver could be on their way or close to the delivery location.
        </Typography>
        <TextField
          fullWidth
          label="Reason"
          margin="dense"
          name="reason"
          onChange={handleChange}
          required
          value={reason}
          variant="outlined"
        />
        <div style={{ marginTop: '10px' }}>
          <LoadingButton
            onClick={() => handleCancel({ reference: order.reference, reason })}
            variant="outlined"
            color="error">
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleClose}
            style={{ marginLeft: '10px' }}
            variant="outlined">
            Close
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

const Order = () => {
  const classes = useStyles();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    sender_data: {},
    driver_data: {},
    items: [],
    images: []
  });
  const [open, setOpen] = React.useState(false);

  const fetchOrder = async ({ id }) => {
    const res = await ordersApi.GetOrder({ id });
    setOrder(res.data.order);
  };

  React.useEffect(() => {
    fetchOrder({ id });
  }, []);

  const mapLoaded = ({ map, maps }) => {
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    const origin = {
      lat: Number(order.sender_address_latitude),
      lng: Number(order.sender_address_longitude)
    };
    const destination = {
      lat: Number(order.receiver_address_latitude),
      lng: Number(order.receiver_address_longitude)
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const onCancel = () => {
    setOpen(true);
  };

  const cancel = async ({ reference, reason }) => {
    setOpen(false);
    setLoading(true);
    const response = await ordersApi.CancelOrder({ reference, reason });
    if (response.success) {
      toast.success(response.message);
      await fetchOrder({ id });
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  return order ? (
    <div className={classes.root}>
      <Toaster />
      <CancelOrderModel
        order={order}
        open={open}
        handleClose={() => setOpen(false)}
        handleCancel={cancel}
      />
      <div style={{ display: 'flex' }}>
        <Typography variant="h3">Order: {order.reference}</Typography>
        <LoadingButton
          loading={loading}
          color="error"
          disabled={
            order.status === 'cancelled' ||
            order.status === 'driver_delivered_items' ||
            order.status === 'order_completed'
          }
          style={{ marginLeft: 'auto' }}
          onClick={onCancel}
          variant="outlined">
          {order.status === 'cancelled' ? 'Cancelled' : 'Cancel'}
        </LoadingButton>
      </div>
      <div className={classes.content}>
        <Grid container>
          <section style={{ width: '100%' }}>
            <Typography variant="h4" style={{ marginBottom: '1rem' }}>
              Order details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Reference"
                  disabled
                  variant="outlined"
                  value={order.reference}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Created Date"
                  disabled
                  variant="outlined"
                  value={moment(order.created_at).format('MMM DD YYYY, HH:mm')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Status"
                  disabled
                  variant="outlined"
                  value={order.status}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Price"
                  disabled
                  variant="outlined"
                  value={order.price}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Driver Fee"
                  disabled
                  variant="outlined"
                  value={order.driver_fee}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Revenue"
                  disabled
                  variant="outlined"
                  value={(order.price - order.driver_fee).toFixed(2)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Delivered Time"
                  disabled
                  variant="outlined"
                  value={
                    order.delivered_at
                      ? moment(order.delivered_at).format('MMM DD YYYY, HH:mm')
                      : 'Not yet delivered'
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Cancelled At"
                  disabled
                  variant="outlined"
                  value={
                    order.cancelled_at
                      ? moment(order.cancelled_at).format('MMM DD YYYY, HH:mm')
                      : 'Not yet cancelled'
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Cancelled By"
                  disabled
                  variant="outlined"
                  value={
                    order.cancelled_by
                      ? order.cancelled_by
                      : 'Not yet cancelled'
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Cancelled Reason"
                  disabled
                  variant="outlined"
                  value={
                    order.cancelled_by
                      ? order.cancelled_reason
                      : 'Not yet cancelled'
                  }
                />
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            <Typography
              variant="h4"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              Sender details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Link
                  component={RouterLink}
                  to={`/senders/${order.sender_data.id}`}>
                  <Card
                    fullWidth
                    label="Sender/Customer Name"
                    disabled
                    variant="outlined"
                    value={order.sender_data.name}
                  />
                </Link>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Sender Phone"
                  disabled
                  variant="outlined"
                  value={
                    order.sender_data.country_code +
                    order.sender_data.phone_number
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Sender Email"
                  disabled
                  variant="outlined"
                  value={order.sender_data.email}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Sender Address"
                  disabled
                  variant="outlined"
                  value={order.sender_address}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Total Orders"
                  disabled
                  variant="outlined"
                  value={order.sender_data.total_orders}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Sender Rating"
                  disabled
                  variant="outlined"
                  value={order.sender_data.overall_rating}
                />
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            <Typography
              variant="h4"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              Receiver details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Receiver Name"
                  disabled
                  variant="outlined"
                  value={order.receiver_name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Receiver Phone"
                  disabled
                  variant="outlined"
                  value={order.receiver_phone}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Receiver Address"
                  disabled
                  variant="outlined"
                  value={order.receiver_address}
                />
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            <Typography
              variant="h4"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              Driver details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Link
                  component={RouterLink}
                  to={`/drivers/${order.driver_data.id}`}>
                  <Card
                    fullWidth
                    label="Driver Name"
                    disabled
                    variant="outlined"
                    value={order.driver_data.name}
                  />
                </Link>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Driver Phone"
                  disabled
                  variant="outlined"
                  value={
                    order.driver_data.country_code +
                    order.driver_data.phone_number
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Overall Rating"
                  disabled
                  variant="outlined"
                  value={order.driver_data.overall_rating}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Total Deliveries"
                  disabled
                  variant="outlined"
                  value={order.driver_data.total_deliveries}
                />
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            <Typography
              variant="h4"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              Items
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TableContainer style={{ maxHeight: 440 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ backgroundColor: 'white' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ backgroundColor: 'white' }}>
                          Name
                        </TableCell>
                        <TableCell style={{ backgroundColor: 'white' }}>
                          Quantity
                        </TableCell>
                        <TableCell style={{ backgroundColor: 'white' }}>
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.items &&
                        order.items.map(item => {
                          return (
                            <TableRow>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>{item.price}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={3} xs={6}>
                <ImageList
                  sx={{ width: 500, height: 450 }}
                  cols={3}
                  rowHeight={200}
                  gap={1}>
                  {order.images &&
                    order.images.map(image => {
                      const url = image.replace('http://', 'https://');
                      return (
                        <ImageListItem key={image}>
                          <img
                            srcSet={`${url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${url}?w=164&h=164&fit=crop&auto=format`}
                            alt={url}
                            loading="lazy"
                          />
                        </ImageListItem>
                      );
                    })}
                </ImageList>
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            {order.sender_address_latitude && order.sender_address_longitude && (
              <div
                style={{ height: '600px', width: '100%', marginTop: '20px' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: apiKey, libraries: ['maps'] }}
                  defaultCenter={{
                    lat: order.sender_address_latitude
                      ? Number(order.sender_address_latitude)
                      : 6.5244,
                    lng: order.sender_address_longitude
                      ? Number(order.sender_address_longitude)
                      : 3.3792
                  }}
                  defaultZoom={11}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={mapLoaded}>  
                </GoogleMapReact>
              </div>
            )}
          </section>
        </Grid>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Order;
