import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import sendersApi from 'apis/senders';
import { Grid, Typography } from '@material-ui/core';
import { Card } from 'components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Order = () => {
  const classes = useStyles();

  const { id } = useParams();

  const [sender, setSender] = useState({ });

  const fetchOrder = async ({ id }) => {
    const res = await sendersApi.GetSender({ id });
    setSender(res.data.sender);
  };

  React.useEffect(() => {
    fetchOrder({ id });
  }, []);

  return sender ? (
    <div className={classes.root}>
      <Typography variant="h3">Sender: {sender.name}</Typography>
      <div className={classes.content}>
        <Grid container>
          <section style={{ width: '100%'}}>
            <Typography variant="h4" style={{ marginBottom: '1rem' }}>
              Sender details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Name"
                  disabled
                  variant="outlined"
                  value={sender.name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Phone"
                  disabled
                  variant="outlined"
                  value={
                    sender.country_code +
                    sender.phone_number
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Email"
                  disabled
                  variant="outlined"
                  value={sender.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Rating"
                  disabled
                  variant="outlined"
                  value={sender.overall_rating}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Total Orders"
                  disabled
                  variant="outlined"
                  value={sender.total_orders}
                />
              </Grid>
            </Grid>
          </section>
        </Grid>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Order;
