import HttpInstance from './axios';


const ExportData = async ({ resource }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/export/${resource}`, { responseType: 'blob' });

    if (response.data) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${resource}.csv`; // The name of the file to be downloaded
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  ExportData,
};
