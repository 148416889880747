import HttpInstance from './axios';
import queryString from 'query-string';

const GetCategories = async ({ page, perPage, search }) => {
  const qs = queryString.stringify({
    page,
    perPage,
    search
  })

  try {
    const response = await HttpInstance.get(`/api/v1/admin/categories?${qs}`)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CreateCategories = async ({ name, active }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/categories', {
      name, active
    })
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const UpdateCategories = async ({ id, name, active }) => {
  try {
    const response = await HttpInstance.patch(`/api/v1/admin/categories/${id}`, {
      name, active
    })
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetItems = async ({ page, perPage, categoryId, weightClass, active, search }) => {
  const qs = queryString.stringify({
    page,
    perPage,
    categoryId, weightClass, active, search
  })

  try {
    const response = await HttpInstance.get(`/api/v1/admin/items?${qs}`)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const UpdateItems = async ({ id, name, categoryId, value, weightClass  }) => {
  try {
    const response = await HttpInstance.patch(`/api/v1/admin/items/${id}`, {
      name, categoryId, value, weightClass
    })
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetItem = async ({ id }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/items/${id}`)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CreateItems = async ({ name, categoryId, value, weightClass }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/items', {
      name, 
      categoryId, 
      value, 
      weightClass
    })
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  CreateItems,
  CreateCategories,
  GetItem,
  GetItems,
  GetCategories,
  UpdateCategories,
  UpdateItems
}


