import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import authApi from 'apis/auth';
import toast, { Toaster } from 'react-hot-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { LoadingButton } from '@mui/lab';


const useStyles = makeStyles(() => ({
  root: {}
}));

const MobileConfig = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const defaultConfig = () => ({ id: `${Math.random(10) * 1000}`,  key: '', value: '' })

  const fetchConfigs = async () => {
    setLoading(true);
    const response = await authApi.GetMobileConfigs();
    setConfigs([...response.data.configs, defaultConfig()]);
    setLoading(false);
  }

  React.useEffect(() => {
    fetchConfigs()
  }, [])

  const addConfig = async () => {
    setLoading(true)
    const emptyKeyOrValue = configs.find(config => config.key.trim() === '' || config.value.trim() === '');

    if (!emptyKeyOrValue) {
      const lastConfig = configs[configs.length - 1];
      const response = await authApi.CreateMobileConfigs({ key: lastConfig.key, value: lastConfig.value })
      if (response.success) {
        toast.success(response.message)
        setConfigs([...response.data.configs, defaultConfig()])
      } else {
        toast.error(response.message)
      }
    }
    setLoading(false)
  };

  const updateConfig = async (id) => {
    setLoading(true)
    const config = configs.find(config => id === config.id)

    if (config && config.key && config.value) {
      const response = await authApi.UpdateMobileConfigs({ id:config.id, key: config.key, value: config.value })
      if (response.success) {
        toast.success(response.message)
        setConfigs([...response.data.configs, defaultConfig()])
      } else {
        toast.error(response.message)
      }
    }
    setLoading(false)
  }

  const deleteConfig = async (id) => {
    setLoading(true)
    const config = configs.find(config => id === config.id)

    if (config && config.key) {
      const response = await authApi.DeleteMobileConfigs({ key: config.key })
      if (response.success) {
        toast.success(response.message)
        setConfigs([...response.data.configs, defaultConfig()])
      } else {
        toast.error(response.message)
      }
    }
    setLoading(false)
  }

  const handleChange = (event, type) => {
    const { name, value } = event.target;

    const newConfigs = configs.map(config => {
      if (String(config.id) === String(name)) {
        return { ...config, [type]: value };
      }
  
      return config;
    });

    setConfigs([...newConfigs]);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Toaster />
      <form>
        <CardHeader
          subheader='Manage Mobile Configurations'
          title='Mobile Config'
        />
        <Divider />
        <CardContent>
          {configs.map(config => {
            return (
              <>
                <div key={config.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label="Key"
                    name={String(config.id)}
                    style={{ margin: '10px' }}
                    onChange={(e) => handleChange(e, 'key')}
                    type="text"
                    value={config.key}
                    variant="outlined"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Value"
                    name={String(config.id)}
                    style={{ margin: '10px' }}
                    onChange={(e) => handleChange(e, 'value')}
                    type="text"
                    value={config.value}
                    variant="outlined"
                    required
                  />
                  <LoadingButton loading={loading} onClick={() => updateConfig(config.id)}>
                    <UploadIcon />
                  </LoadingButton>
                  <LoadingButton loading={loading} onClick={() => deleteConfig(config.id)}>
                    <DeleteIcon />
                  </LoadingButton>
                </div>
              </>
            );
          })}
        </CardContent>
        <CardActions>
          <Button style={{ marginLeft: '10px' }} onClick={addConfig} color="primary" variant="outlined">
            Add Config
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

MobileConfig.propTypes = {
  className: PropTypes.string
};

export default MobileConfig;
