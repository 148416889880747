import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import authApi from 'apis/auth';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: '',
    current: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async () => {
    if (values.password !== values.confirm) {
      return toast.error('Passwords do not match')
    }
    
    const response = await authApi.UpdatePassword({ currentPassword: values.current, newPassword: values.password })

    if (response.success) {
      toast.success(response.message)
      setValues({
        password: '',
        confirm: '',
        current: ''
      })
    } else {
      toast.error(response.message)
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Toaster />
      <form>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Current Password"
            name="current"
            onChange={handleChange}
            type="password"
            value={values.current}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="New Password"
            name="password"
            style={{ marginTop: '1rem' }}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button onClick={handleSubmit} color="primary" variant="outlined">
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
