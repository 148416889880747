import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  // Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Avatar
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const driversTable = props => {
  const {
    className,
    drivers,
    metaData,
    fetchDrivers,
    toolbarOptions,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    toolbarOptions ? toolbarOptions.perPage : 10
  );
  const [page, setPage] = useState(
    toolbarOptions ? toolbarOptions.page - 1 : 0
  );

  const handleSelectAll = event => {
    const { drivers } = props;

    let selectedDrivers;

    if (event.target.checked) {
      selectedDrivers = drivers.map(user => user.id);
    } else {
      selectedDrivers = [];
    }

    setSelectedDrivers(selectedDrivers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedDrivers.indexOf(id);
    let newSelectedDrivers = [];

    if (selectedIndex === -1) {
      newSelectedDrivers = newSelectedDrivers.concat(selectedDrivers, id);
    } else if (selectedIndex === 0) {
      newSelectedDrivers = newSelectedDrivers.concat(selectedDrivers.slice(1));
    } else if (selectedIndex === selectedDrivers.length - 1) {
      newSelectedDrivers = newSelectedDrivers.concat(
        selectedDrivers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedDrivers = newSelectedDrivers.concat(
        selectedDrivers.slice(0, selectedIndex),
        selectedDrivers.slice(selectedIndex + 1)
      );
    }

    setSelectedDrivers(newSelectedDrivers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchDrivers({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchDrivers({ page: page + 1, perPage: event.target.value });
  };

  const navigateToDriverDetails = id => {
    props.history.push(`/drivers/${id}`);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedDrivers.length === drivers.length}
                      color="primary"
                      indeterminate={
                        selectedDrivers.length > 0 &&
                        selectedDrivers.length < drivers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Last Login At</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Verified</TableCell>
                  <TableCell>Total Deliveries</TableCell>
                  <TableCell>Overall Rating</TableCell>
                  <TableCell>Online</TableCell>
                  <TableCell>In an order</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drivers.map(driver => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={driver.id}
                    selected={selectedDrivers.indexOf(driver.id) !== -1}
                    onClick={() => navigateToDriverDetails(driver.id)}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedDrivers.indexOf(driver.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, driver.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {driver.last_login_at ?moment(driver.last_login_at.toString()).format(
                            'MMM DD YYYY, HH:mm'
                          ): 'No login'}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {moment(driver.created_at.toString()).format(
                            'MMM DD YYYY, HH:mm'
                          )}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Avatar className={classes.avatar} src={driver.avatar_url}>
                          {getInitials(driver.name)}
                        </Avatar>
                        {driver.name}
                      </div>
                    </TableCell>
                    <TableCell>{driver.email}</TableCell>
                    <TableCell>
                      {driver.country_code}
                      {driver.phone_number}
                    </TableCell>
                    <TableCell>{driver.verified ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{driver.total_deliveries}</TableCell>
                    <TableCell>{driver.overall_rating}</TableCell>
                    <TableCell>
                      {driver.online &&
                      driver.latitude &&
                      driver.longitude &&
                      driver.location
                        ? 'Yes'
                        : 'No'}
                    </TableCell>
                    <TableCell>{driver.in_ride ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

driversTable.propTypes = {
  className: PropTypes.string,
  drivers: PropTypes.array.isRequired
};

export default withRouter(driversTable);
