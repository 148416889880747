import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  // Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

// import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SendersTable = props => {
  const { className, senders, metaData, fetchSenders, ...rest } = props;

  const classes = useStyles();

  const [selectedSenders, setSelectedSenders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { senders } = props;

    let selectedSenders;

    if (event.target.checked) {
      selectedSenders = senders.map(user => user.id);
    } else {
      selectedSenders = [];
    }

    setSelectedSenders(selectedSenders);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSenders.indexOf(id);
    let newSelectedSenders = [];

    if (selectedIndex === -1) {
      newSelectedSenders = newSelectedSenders.concat(selectedSenders, id);
    } else if (selectedIndex === 0) {
      newSelectedSenders = newSelectedSenders.concat(selectedSenders.slice(1));
    } else if (selectedIndex === selectedSenders.length - 1) {
      newSelectedSenders = newSelectedSenders.concat(selectedSenders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedSenders.slice(0, selectedIndex),
        selectedSenders.slice(selectedIndex + 1)
      );
    }

    setSelectedSenders(newSelectedSenders);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchSenders({ page: page + 1, perPage: rowsPerPage })
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchSenders({ page: page + 1, perPage: event.target.value})
  };

  const navigateToSenderDetails = id => {
    props.history.push(`/senders/${id}`);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedSenders.length === senders.length}
                      color="primary"
                      indeterminate={
                        selectedSenders.length > 0 &&
                        selectedSenders.length < senders.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Last Login At</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Total orders</TableCell>
                  <TableCell>Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {senders.map(sender => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={sender.id}
                    selected={selectedSenders.indexOf(sender.id) !== -1}
                    onClick={() => navigateToSenderDetails(sender.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedSenders.indexOf(sender.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, sender.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{sender.last_login_at ? moment(sender.last_login_at.toString()).format('MMM DD YYYY, HH:mm') : 'No login'}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{moment(sender.created_at.toString()).format('MMM DD YYYY, HH:mm')}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{sender.name}</TableCell>
                    <TableCell>{sender.email}</TableCell>
                    <TableCell>{sender.country_code}{sender.phone_number}</TableCell>
                    <TableCell>{sender.total_orders}</TableCell>
                    <TableCell>{sender.overall_rating}</TableCell>
                    <TableCell>{sender.receiver_address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

SendersTable.propTypes = {
  className: PropTypes.string,
  senders: PropTypes.array.isRequired
};

export default withRouter(SendersTable);
