import React from 'react';
import ReactDOM from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <App />
  </LocalizationProvider>
);

serviceWorker.unregister();
