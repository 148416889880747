import HttpInstance from './axios';
import queryString from 'query-string';


const GetOrders = async ({ page = 1, perPage = 10, search, status }) => {
  const qs = queryString.stringify({
    page,
    per_page: perPage,
    search,
    status
  })

  try {
    const response = await HttpInstance.get(`/api/v1/admin/orders?${qs}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetOrder = async ({ id }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/orders/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CancelOrder = async ({ reference, reason }) => {
  try {
    const response = await HttpInstance.post(`/api/v1/admin/orders/${reference}/cancel`, { reason });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  GetOrders,
  GetOrder,
  CancelOrder
};
