import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

// import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const AccountTable = props => {
  const { className, accounts, metaData, fetchAccounts, removeAccount, ...rest } = props;

  const classes = useStyles();

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { accounts } = props;

    let selectedAccounts;

    if (event.target.checked) {
      selectedAccounts = accounts.map(user => user.id);
    } else {
      selectedAccounts = [];
    }

    setSelectedAccounts(selectedAccounts);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAccounts.indexOf(id);
    let newSelectedSenders = [];

    if (selectedIndex === -1) {
      newSelectedSenders = newSelectedSenders.concat(selectedAccounts, id);
    } else if (selectedIndex === 0) {
      newSelectedSenders = newSelectedSenders.concat(selectedAccounts.slice(1));
    } else if (selectedIndex === selectedAccounts.length - 1) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedAccounts.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedAccounts.slice(0, selectedIndex),
        selectedAccounts.slice(selectedIndex + 1)
      );
    }

    setSelectedAccounts(newSelectedSenders);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchAccounts({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchAccounts({ page: page + 1, perPage: event.target.value });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAccounts.length === accounts.length}
                      color="primary"
                      indeterminate={
                        selectedAccounts.length > 0 &&
                        selectedAccounts.length < accounts.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Last Login At</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Password Updated</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map(account => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={account.id}
                    selected={selectedAccounts.indexOf(account.id) !== -1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAccounts.indexOf(account.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, account.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {account.last_login_at ? moment(account.last_login_at.toString()).format(
                            'MMM DD YYYY, HH:mm'
                          ) : 'No login'}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {moment(account.created_at.toString()).format(
                            'MMM DD YYYY, HH:mm'
                          )}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{account.name}</TableCell>
                    <TableCell>{account.email}</TableCell>
                    <TableCell>{account.role}</TableCell>
                    <TableCell>
                      {account.password_reset ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Button
                        onClick={() => removeAccount(account.id)}
                        color="primary"
                        variant="outlined"
                        disabled={account.role === 'super_admin' || account.deleted}
                      >
                        {account.deleted ? 'Removed' : 'Remove'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

AccountTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired
};

export default AccountTable;
