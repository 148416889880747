import HttpInstance from './axios';
import queryString from 'query-string';

const GetDrivers = async ({ page = 1, perPage = 10, presence = 'both', search, online, inOrder, verified }) => {
  const qs = queryString.stringify({
    page,
    per_page: perPage,
    presence,
    search,
    online,
    inOrder,
    verified
  })

  try {
    const response = await HttpInstance.get(`/api/v1/admin/drivers?${qs}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetDriver = async ({ id }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/drivers/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  GetDrivers,
  GetDriver
};
