import HttpInstance from './axios';
import queryString from 'query-string';


const GetSenders = async ({ page = 1, perPage = 10, search }) => {
  const qs = queryString.stringify({
    page,
    per_page: perPage,
    search,
  })

  try {
    const response = await HttpInstance.get(`/api/v1/admin/senders?${qs}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetSender = async ({ id }) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/senders/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  GetSenders,
  GetSender
};
