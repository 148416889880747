import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  OrderList as OrderListView,
  SendersList as SendersListView,
  DriverList as DriverListView,
  PushNotification as PushNotificationView,
  OrderDetail as OrderDetailView,
  SenderDetail as SenderDetailView,
  DriverDetail as DriverDetailView,
  DriverOnline as DriverOnlineView,
  CategoryList as CategoriesListView,
  Items as ItemsView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Route component={SignInView} exact layout={MainLayout} path="/" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={OrderListView}
        exact
        layout={MainLayout}
        path="/orders"
      />
      <RouteWithLayout
        component={OrderDetailView}
        exact
        layout={MainLayout}
        path="/orders/:id"
      />

      <RouteWithLayout
        component={SendersListView}
        exact
        layout={MainLayout}
        path="/senders"
      />
      <RouteWithLayout
        component={SenderDetailView}
        exact
        layout={MainLayout}
        path="/senders/:id"
      />
      <RouteWithLayout
        component={DriverListView}
        exact
        layout={MainLayout}
        path="/drivers"
      />
      <RouteWithLayout
        component={CategoriesListView}
        exact
        layout={MainLayout}
        path="/categories"
      />
      <RouteWithLayout
        component={ItemsView}
        exact
        layout={MainLayout}
        path="/items"
      />
      <RouteWithLayout
        component={DriverOnlineView}
        exact
        layout={MainLayout}
        path="/online"
      />
      <RouteWithLayout
        component={DriverDetailView}
        exact
        layout={MainLayout}
        path="/drivers/:id"
      />
      <RouteWithLayout
        component={PushNotificationView}
        exact
        layout={MainLayout}
        path="/push-notification"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
