import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import ItemsModal from '../ItemsModal';
var currencyFormatter = require('currency-formatter');


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ItemsTable = props => {
  const {
    className,
    items,
    metaData,
    fetchItems,
    toolbarOptions,
    categories,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    toolbarOptions ? toolbarOptions.perPage : 10
  );
  const [page, setPage] = useState(
    toolbarOptions ? toolbarOptions.page - 1 : 0
  );

  const handleSelectAll = event => {
    const { items } = props;

    let selectedItems;

    if (event.target.checked) {
      selectedItems = items.map(user => user.id);
    } else {
      selectedItems = [];
    }

    setSelectedItems(selectedItems);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [];

    if (selectedIndex === -1) {
      newSelectedItems = newSelectedItems.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(
        selectedItems.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelectedItems);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchItems({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchItems({ page: page + 1, perPage: event.target.value });
  };

  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({})

  const openModal = (item) => {
    setSelectedCategory(item)
    setOpen(true)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <ItemsModal 
        open={open} 
        state='edit'  
        handleClose={() => setOpen(false)} 
        item={selectedCategory} 
        onSuccess={() => fetchItems({ ...toolbarOptions })} 
        categories={categories}
      />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedItems.length === items.length}
                      color="primary"
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < items.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>(N)Value</TableCell>
                  <TableCell>Weight Class</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                    selected={selectedItems.indexOf(item.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.indexOf(item.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.category.name}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{currencyFormatter.format(Number(item.value), { code: 'NGN'})}</TableCell>
                    <TableCell>{item.weight_class}</TableCell>
                    <TableCell>{item.active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => openModal(item)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

ItemsTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default withRouter(ItemsTable);
