import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const PushNotificationTable = props => {
  const {
    className,
    notifications,
    metaData,
    fetchNotifications,
    updatePushNotification,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { notifications } = props;

    let selectedNotifications;

    if (event.target.checked) {
      selectedNotifications = notifications.map(user => user.id);
    } else {
      selectedNotifications = [];
    }

    setSelectedNotifications(selectedNotifications);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedNotifications.indexOf(id);
    let newSelectedSenders = [];

    if (selectedIndex === -1) {
      newSelectedSenders = newSelectedSenders.concat(selectedNotifications, id);
    } else if (selectedIndex === 0) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedNotifications.slice(1)
      );
    } else if (selectedIndex === selectedNotifications.length - 1) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedNotifications.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedSenders = newSelectedSenders.concat(
        selectedNotifications.slice(0, selectedIndex),
        selectedNotifications.slice(selectedIndex + 1)
      );
    }

    setSelectedNotifications(newSelectedSenders);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchNotifications({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchNotifications({ page: page + 1, perPage: event.target.value });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedNotifications.length === notifications.length
                      }
                      color="primary"
                      indeterminate={
                        selectedNotifications.length > 0 &&
                        selectedNotifications.length < notifications.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Sent By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Scheduled Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.map(notification => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={notification.id}
                    selected={
                      selectedNotifications.indexOf(notification.id) !== -1
                    }>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedNotifications.indexOf(notification.id) !== -1
                        }
                        color="primary"
                        onChange={event =>
                          handleSelectOne(event, notification.id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {moment(notification.created_at.toString()).format(
                            'MMM DD YYYY, HH:mm'
                          )}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{notification.user_type}</TableCell>
                    <TableCell>{notification.title}</TableCell>
                    <TableCell>{notification.description}</TableCell>
                    <TableCell>{notification.sent_by}</TableCell>
                    <TableCell>{notification.status}</TableCell>
                    <TableCell>
                      {notification.scheduled_date_time ? moment(
                        notification.scheduled_date_time.toString()
                      ).format('MMM DD YYYY, HH:mm') : 'N/A'}
                    </TableCell>
                    <TableCell style={{ display: 'flex'}}>
                      <Button
                        onClick={() => updatePushNotification(notification.id, 'cancel')}
                        color="primary"
                        variant="outlined"
                        disabled={notification.status === 'sent' || notification.status === 'cancelled'}
                        size='small'
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ marginLeft: '5px'}}
                        onClick={() => updatePushNotification(notification.id, 'send')}
                        color="primary"
                        variant="outlined"
                        disabled={notification.status === 'sent' || notification.status === 'cancelled'}
                        size='small'
                      >
                        Send
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

PushNotificationTable.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
};

export default PushNotificationTable;
