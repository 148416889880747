import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import PushNotificationTable from '../PushNotificationTable';
import notificationsApi from 'apis/notifications';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {},
  topSpace: {
    marginTop: '1rem'
  },
  textarea: {
    resize: 'both'
  },
  fullWidth: {
    width: '100%'
  }
}));

const PushNotificationDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    title: '',
    userType: 'all',
    message: '',
    scheduledDateTime: null
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDateChange = (date) => {
    setValues({ ...values, scheduledDateTime: date });
  }

  const userTypes = [
    {
      value: 'senders',
      label: 'Senders'
    },
    {
      value: 'drivers',
      label: 'Drivers'
    },
    {
      value: 'all',
      label: 'All'
    }
  ];

  const [metaData, setMetaData] = useState({});
  const [notifications, setPushNotification] = React.useState([]);

  const fetchNotifications = async ({ page = 1, perPage = 10 }) => {
    const response = await notificationsApi.GetPushNotifications({
      page,
      perPage
    });
    setPushNotification(response.data.data);
    setMetaData(response.data.meta);
  };

  React.useEffect(() => {
    fetchNotifications({ page: 1, perPage: 10 });
  }, []);

  const createPushNotification = async () => {
    const response = await notificationsApi.CreatePushNotification({
      title: values.title,
      message: values.message,
      userType: values.userType,
      scheduledDateTime: values.scheduledDateTime ? values.scheduledDateTime.format('YYYY-MM-DDTHH:mm') : null
    });

    if (response.success) {
      toast.success(response.message);
      setValues({
        title: '',
        message: '',
        userType: 'all',
        scheduledDateTime: null
      });
      fetchNotifications({ page: 1, perPage: 10 });
    } else {
      toast.error(response.message);
    }
  };

  const updatePushNotification = async (id, action) => {
    const response = await notificationsApi.UpdatePushNotification({ id, action });

    if (response.success) {
      toast.success(response.message);
      fetchNotifications({ page: 1, perPage: 10 });
    } else {
      toast.error(response.message);
    }
  }

  return (
    <div>
      <Toaster />
      {
        <Card {...rest} className={clsx(classes.root, className)}>
          <form autoComplete="off" noValidate>
            <CardHeader
              subheader="Create a push notification to Drivers, Senders or Both"
              title="Push Notification"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    margin="dense"
                    name="title"
                    onChange={handleChange}
                    required
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Select Users"
                    margin="dense"
                    name="userType"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.userType}
                    variant="outlined">
                    {userTypes.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    label="Message"
                    margin="dense"
                    name="message"
                    onChange={handleChange}
                    required
                    value={values.message}
                    variant="outlined"
                    className={clsx(classes.textare, classes.fullWidth)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    name="scheduledDateTime"
                    value={values.scheduledDateTime}
                    onChange={(date) => handleDateChange(date)}
                    label="Schedule date and time"
                    minDate={moment()}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                onClick={createPushNotification}
                color="primary"
                variant="contained">
                Send
              </Button>
            </CardActions>
          </form>
          <PushNotificationTable
            className={clsx(classes.topSpace)}
            notifications={notifications}
            fetchNotifications={fetchNotifications}
            metaData={metaData}
            updatePushNotification={updatePushNotification}
          />
        </Card>
      }
    </div>
  );
};

PushNotificationDetails.propTypes = {
  className: PropTypes.string
};

export default PushNotificationDetails;
