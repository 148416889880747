import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import senderApi from 'apis/senders';
import exportApi from 'apis/export';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';


import { SendersToolbar, SendersTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SenderList = (props) => {
  const classes = useStyles();

  const [senders, setSenders] = useState([])
  const [metaData, setMetaData] = useState({})
  const [toolbarOptions, setToolbarOptions] = useState({});
  const [loading, setLoading] = useState(false)

  const fetchSenders = async ({ page = 1, perPage = 10}) => {
    setLoading(true)
    const qs = queryString.stringify({ ...toolbarOptions, page, perPage  })
    const res = await senderApi.GetSenders({ ...toolbarOptions, page, perPage,  })
    setSenders(res.data.senders)
    setMetaData(res.data.meta)
    props.history.push(`/senders?${qs}`)
    setLoading(false)
  }

  const exportData = async () => {
    setLoading(true)
    await exportApi.ExportData({ resource: 'senders' })
    setLoading(false)
  }

  React.useEffect(() => {
    fetchSenders({})
  }, [])

  return (
    <div className={classes.root}>
      <SendersToolbar 
        toolbarOptions={toolbarOptions} 
        setToolbarOptions={setToolbarOptions} 
        search={fetchSenders} 
        loading={loading} 
        exportData={exportData}
      />
      <div className={classes.content}>
        <SendersTable 
          senders={senders} 
          metaData={metaData} 
          fetchSenders={fetchSenders}
          toolbarOptions={toolbarOptions} 
          setToolbarOptions={setToolbarOptions}
        />
      </div>
    </div>
  );
};

export default withRouter(SenderList);
