import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import itemsApi from 'apis/items';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { ItemsToolbar, ItemsTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ItemsList = (props) => {
  const classes = useStyles();

  const [items, setItems] = useState([])
  const [categories, setCategories] = useState([])
  const [metaData, setMetaData] = useState({})
  const [toolbarOptions, setToolbarOptions] = useState(queryString.parse(props.location.search));
  const [loading, setLoading] = useState(false)

  const fetchItems = async ({ page = 1, perPage = 25 }) => {
    setLoading(true)
    const qs = queryString.stringify({ ...toolbarOptions, page, perPage })
    const res = await itemsApi.GetItems({ ...toolbarOptions, page, perPage })
    setItems(res.data.items)
    setMetaData(res.data.meta)
    props.history.push(`/items?${qs}`)
    setLoading(false)
  }

  const fetchCategories = async ({ page = 1, perPage = 5000 }) => {
    setLoading(true)
    const res = await itemsApi.GetCategories({ page, perPage })
    setCategories(res.data.categories)
    setLoading(false)
  }


  React.useEffect(() => {
    setToolbarOptions(queryString.parse(props.location.search))
    fetchItems({})
    fetchCategories({})
  }, [])

  return (
    <div className={classes.root}>
      <ItemsToolbar
        toolbarOptions={toolbarOptions} 
        setToolbarOptions={setToolbarOptions} 
        search={fetchItems} 
        loading={loading}
        categories={categories}
      />
      <div className={classes.content}>
        <ItemsTable  
          items={items} 
          metaData={metaData} 
          fetchItems={fetchItems} 
          toolbarOptions={toolbarOptions} 
          setToolbarOptions={setToolbarOptions} 
          categories={categories}
        />
      </div>
    </div>
  );
};

export default withRouter(ItemsList);
