import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import itemsApi from 'apis/items';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { CategoriesToolbar, CategoriesTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const CategoriesList = (props) => {
  const classes = useStyles();

  const [categories, setCategories] = useState([])
  const [metaData, setMetaData] = useState({})
  const [toolbarOptions, setToolbarOptions] = useState(queryString.parse(props.location.search));
  const [loading, setLoading] = useState(false)

  const fetchCategories = async ({ page = 1, perPage = 25 }) => {
    setLoading(true)
    const qs = queryString.stringify({ ...toolbarOptions, page, perPage })
    const res = await itemsApi.GetCategories({ ...toolbarOptions, page, perPage })
    setCategories(res.data.categories)
    setMetaData(res.data.meta)
    props.history.push(`/categories?${qs}`)
    setLoading(false)
  }


  React.useEffect(() => {
    setToolbarOptions(queryString.parse(props.location.search))
    fetchCategories({})
  }, [])

  return (
    <div className={classes.root}>
      <CategoriesToolbar
        toolbarOptions={toolbarOptions} 
        setToolbarOptions={setToolbarOptions} 
        search={fetchCategories} 
        loading={loading}
      />
      <div className={classes.content}>
        <CategoriesTable  
          categories={categories} 
          metaData={metaData} 
          fetchCategories={fetchCategories} 
          toolbarOptions={toolbarOptions} 
          setToolbarOptions={setToolbarOptions} 
        />
      </div>
    </div>
  );
};

export default withRouter(CategoriesList);
