import HttpInstance from './axios';

const Login = async ({ email, password }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/login', { email, password });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const Profile = async () => {
  try {
    const response = await HttpInstance.get('/api/v1/admin/profile');
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const UpdatePassword = async ({ newPassword, currentPassword }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/password', { newPassword, oldPassword: currentPassword});
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CreateAdmin = async ({ email, name, role }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/create', { email, name, role });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetAdmins = async ({ page = 1, perPage = 10}) => {
  try {
    const response = await HttpInstance.get(`/api/v1/admin/all?page=${page}&perPage=${perPage}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const RemoveAdmin = async ({ id }) => {
  try {
    const response = await HttpInstance.delete('/api/v1/admin/remove/' + id);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const GetMobileConfigs = async () => {
  try {
    const response = await HttpInstance.get('/api/v1/admin/mobile_configs');
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const UpdateMobileConfigs = async ({ id, key, value }) => {
  try {
    const response = await HttpInstance.patch('/api/v1/admin/mobile_configs', { id, key, value });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const CreateMobileConfigs = async ({ key, value }) => {
  try {
    const response = await HttpInstance.post('/api/v1/admin/mobile_configs', { key, value });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const DeleteMobileConfigs = async ({ key }) => {
  try {
    const response = await HttpInstance.delete(`/api/v1/admin/mobile_configs/${key}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export default {
  Login,
  Profile,
  UpdatePassword,
  CreateAdmin,
  GetAdmins,
  RemoveAdmin,
  GetMobileConfigs,
  UpdateMobileConfigs,
  CreateMobileConfigs,
  DeleteMobileConfigs
};
