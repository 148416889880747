import React from 'react';
import Modal from '@mui/material/Modal';
import { Typography, Box, TextField } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import toast, { Toaster } from 'react-hot-toast';
import itemsApi from 'apis/items';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ItemsModal = ({
  open,
  handleClose,
  state = 'create',
  item,
  onSuccess = () => null,
  categories = []
}) => {
  const [data, setData] = React.useState(
    item && item.name ? item : { name: '', active: 'true', category: {} }
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (item && Object.keys(item).length) {
      setData(item);
      console.log(item)
    }
  }, [item]);

  const handleChange = event => {
    data[event.target.name] = event.target.value;
    setData({ ...data });
  };

  const onClose = () => {
    if (item && item.name) {
      setData(item);
    }
    handleClose();
  };

  const createItem = async () => {
    setLoading(true);
    const response = await itemsApi.CreateItems({ categoryId: data.category_id, value: data.value, weightClass: data.weight_class, name: data.name });

    if (response.success) {
      toast.success(response.message);
      onSuccess();
      handleClose();
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const updateItem = async () => {
    setLoading(true);
    const response = await itemsApi.UpdateItems({ id: data.id, categoryId: data.category_id, value: data.value, weightClass: data.weight_class, name: data.name });


    if (response.success) {
      toast.success(response.message);
      onSuccess();
      handleClose();
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const handleSuccess = async () => {
    if (state === 'create') {
      await createItem();
    }

    if (state === 'edit') {
      await updateItem();
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            {state === 'create' ? 'Create Item' : 'Edit Item'}
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            name="category_id"
            label="Category"
            variant="outlined"
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            value={data.category_id}>
            <option disabled>Select Category</option>
            {categories.map(category => {
              return <option key={category.id} value={category.id}>{category.name}</option>;
            })}
          </TextField>
          <TextField
            fullWidth
            label="Name"
            margin="dense"
            name="name"
            onChange={handleChange}
            required
            value={data.name}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Value"
            margin="dense"
            name="value"
            onChange={handleChange}
            required
            value={data.value}
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="dense"
            name="weight_class"
            label="Weight Class"
            variant="outlined"
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            value={data.weight_class}>
            <option value="large">Large</option>
            <option value="medium">Medium</option>
            <option value="small">Small</option>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            name="active"
            label="Active"
            variant="outlined"
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            value={data.active}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </TextField>
          <div style={{ marginTop: '10px' }}>
            <LoadingButton
              disabled={loading}
              loading={loading}
              onClick={handleSuccess}
              variant="outlined"
              color="success">
              {state === 'create' ? 'Create' : 'Edit'}
            </LoadingButton>
            <LoadingButton
              disabled={loading}
              loading={loading}
              onClick={onClose}
              style={{ marginLeft: '10px' }}
              variant="outlined">
              Close
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ItemsModal;
