import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import DashboardApi from './../../apis/dashboard';


import {
  Budget,
  TotalUsers,
  TotalProfit,
  PieChart,
  LatestOrders
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [data, setData ] = React.useState({
    totalDrivers: 0,
    totalSenders: 0,
    totalOrders: 0,
    revenue: 0,
    verifiedDrivers: 0,
    onlineDrivers: 0,
    activeOrders: 0,
    completedOrders: 0,
    cancelledOrders: 0,
    draftOrders: 0
  });

  const FetchDashboardData = async () => {
    const response = await DashboardApi.GetDashboardData();
    setData(response.data);
  }

  React.useEffect(() => {
    FetchDashboardData();
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers title='Total Drivers' amount={data.totalDrivers} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers title='Total Senders' amount={data.totalSenders} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget title='Total Orders' value={data.totalOrders} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit title='Revenue' value={data.revenue} />
        </Grid>
        {/* <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales />
        </Grid> */}
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <PieChart 
            title='Drivers Verification' 
            data={[data.verifiedDrivers, data.totalDrivers - data.verifiedDrivers]} 
            labels={['Verified', 'Unverified']} 
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <PieChart 
            title='Drivers Online'
            headerLinkTo='/online'
            data={[data.onlineDrivers, data.totalDrivers - data.onlineDrivers]} 
            labels={['Online', 'Offline']} 
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <PieChart 
            title='Orders Status' 
            data={[data.completedOrders,  data.cancelledOrders, data.activeOrders, data.draftOrders,]} 
            labels={['Completed', 'Cancelled', 'Active', 'Draft']} 
          />
        </Grid>
        {/* <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid> */}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <LatestOrders orders={data.recentOrders} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
