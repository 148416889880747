import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import driversApi from 'apis/drivers';

import { Grid, Typography } from '@material-ui/core';
import { Card } from 'components';
import DriverProfile from './DriverProfile';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AnyReactComponent = () => <LocationOnIcon />;

const Order = () => {
  const classes = useStyles();

  const { id } = useParams();

  const [driver, setDriver] = useState({});

  const fetchDriver = async ({ id }) => {
    const res = await driversApi.GetDriver({ id });
    setDriver(res.data.driver);
  };

  React.useEffect(() => {
    fetchDriver({ id });
  }, []);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  return driver ? (
    <div className={classes.root}>
      <Typography variant="h3">Driver: {driver.name}</Typography>
      <div className={classes.content}>
        <Grid container>
          <section style={{ width: '100%' }}>
            <Typography variant="h4" style={{ marginBottom: '1rem' }}>
              Driver details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <DriverProfile driver={driver} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Overall Rating"
                  disabled
                  variant="outlined"
                  value={driver.overall_rating}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Total Deliveries"
                  disabled
                  variant="outlined"
                  value={driver.total_deliveries}
                />
              </Grid>
            </Grid>
          </section>

          <section style={{ width: '100%' }}>
            <Typography
              variant="h4"
              style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              Driver Verification
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="BVN"
                  disabled
                  variant="outlined"
                  value={driver.bvn || 'Not Inserted'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="BVN Valid"
                  disabled
                  variant="outlined"
                  value={driver.bvn_valid ? 'Yes' : 'No'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Driver License"
                  disabled
                  variant="outlined"
                  value={driver.driver_licence || 'Not Inserted'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Driver License Valid"
                  disabled
                  variant="outlined"
                  value={driver.driver_licence_valid ? 'Yes' : 'No'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Face Matched"
                  disabled
                  variant="outlined"
                  value={driver.face_matched ? 'Yes' : 'No'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card
                  fullWidth
                  label="Rider Card"
                  disabled
                  variant="outlined"
                  value={driver.rider_card_url || 'Not Inserted'}
                />
              </Grid>
            </Grid>
          </section>
          <section style={{ width: '100%' }}>
            {driver.latitude && driver.longitude && (driver.online || driver.inRide) && (
              <div style={{ height: '600px', width: '100%', marginTop: '20px' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: apiKey }}
                  defaultCenter={{ lat: driver.latitude ? Number(driver.latitude) : 6.5244, lng: driver.longitude ? Number(driver.longitude) : 3.3792}}
                  defaultZoom={defaultProps.zoom}>
                  <AnyReactComponent
                    lat={Number(driver.latitude) }
                    lng={Number(driver.longitude)}
                  />
                </GoogleMapReact>
              </div>
            )}
          </section>
        </Grid>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Order;
