import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ordersApi from 'apis/orders';
import exportApi from 'apis/export';
import queryString from 'query-string';

import { OrdersToolbar, OrdersTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OrderList = (props) => {
  const classes = useStyles();

  const [orders, setOrders] = useState([])
  const [metaData, setMetaData] = useState({})
  const [toolbarOptions, setToolbarOptions] = useState({});
  const [loading, setLoading] = useState(false)

  const fetchOrders = async ({ page = 1, perPage = 10}) => {
    setLoading(true)
    const qs = queryString.stringify({ 
      ...toolbarOptions, 
      page, 
      perPage, 
    })
    const res = await ordersApi.GetOrders({ ...toolbarOptions, page, perPage})
    setOrders(res.data.orders)
    setMetaData(res.data.meta)
    props.history.push(`/orders?${qs}`)
    setLoading(false)
  }

  const exportData = async () => {
    setLoading(true)
    await exportApi.ExportData({ resource: 'orders' })
    setLoading(false)
  }

  React.useEffect(() => {
    fetchOrders({})
  }, [])

  return (
    <div className={classes.root}>
      <OrdersToolbar 
        toolbarOptions={toolbarOptions} 
        setToolbarOptions={setToolbarOptions} 
        search={fetchOrders} 
        loading={loading}
        exportData={exportData}
      />
      <div className={classes.content}>
        <OrdersTable 
          orders={orders} 
          metaData={metaData} 
          fetchOrders={fetchOrders}
          toolbarOptions={toolbarOptions} 
          setToolbarOptions={setToolbarOptions}
        />
      </div>
    </div>
  );
};

export default withRouter(OrderList);
