import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import authApi from 'apis/auth';


import {  Password, MobileConfig } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  const [account, setAccount] = React.useState()

  const fetchProfile = async () => {
    const response = await authApi.Profile()
    setAccount(response.data.admin)
  }

  React.useEffect(() => {
    fetchProfile()
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <Password />
        </Grid>
        {account && account.role === 'super_admin' && <Grid
          item
          md={12}
          xs={12}
        >
          <MobileConfig />
        </Grid>}
      </Grid>
    </div>
  );
};

export default Settings;
