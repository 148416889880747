import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import driversApi from 'apis/drivers';
import exportApi from 'apis/export';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { DriversToolbar, DriversTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const DriverList = (props) => {
  const classes = useStyles();

  const [drivers, setDrivers] = useState([])
  const [metaData, setMetaData] = useState({})
  const [toolbarOptions, setToolbarOptions] = useState(queryString.parse(props.location.search));
  const [loading, setLoading] = useState(false)

  const fetchDrivers = async ({ page = 1, perPage = 10}) => {
    setLoading(true)
    const qs = queryString.stringify({ ...toolbarOptions, page, perPage,  })
    const res = await driversApi.GetDrivers({ ...toolbarOptions, page, perPage,  })
    setDrivers(res.data.drivers)
    setMetaData(res.data.meta)
    props.history.push(`/drivers?${qs}`)
    setLoading(false)
  }

  const exportData = async () => {
    setLoading(true)
    await exportApi.ExportData({ resource: 'drivers' })
    setLoading(false)
  }


  React.useEffect(() => {
    setToolbarOptions(queryString.parse(props.location.search))
    fetchDrivers({})
  }, [])

  return (
    <div className={classes.root}>
      <DriversToolbar 
        toolbarOptions={toolbarOptions} 
        setToolbarOptions={setToolbarOptions} 
        search={fetchDrivers} 
        loading={loading}
        exportData={exportData}
      />
      <div className={classes.content}>
        <DriversTable  
          drivers={drivers} 
          metaData={metaData} 
          fetchDrivers={fetchDrivers} 
          toolbarOptions={toolbarOptions} 
          setToolbarOptions={setToolbarOptions} 
        />
      </div>
    </div>
  );
};

export default withRouter(DriverList);
