import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from 'components';
import { TextField } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  flexEnd: {
    marginLeft: 'auto'
  }
}));

const DriverToolbar = props => {
  const { className, setToolbarOptions, toolbarOptions, search, loading, exportData, ...rest } = props;

  const classes = useStyles();

  const handleChange = (e) => {
    setToolbarOptions({...toolbarOptions, [e.target.name]: e.target.value})
  }
  

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search drivers"
          name='search'
          onChange={handleChange}
          value={toolbarOptions.search}
        />
        <TextField
          style={{ width: '100px', margin: '5px' }}
          margin='dense'
          name='verified'
          label='Verified'
          variant="outlined"
          select
          onChange={handleChange}
          SelectProps={{ native: true }}
          value={toolbarOptions.verified}
        >
          <option value='all'>All</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </TextField>
        <TextField
          style={{ width: '100px', margin: '5px' }}
          margin='dense'
          name='online'
          label='Online'
          variant="outlined"
          select
          SelectProps={{ native: true }}
          onChange={handleChange}
          value={toolbarOptions.online}
        >
          <option value='all'>All</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </TextField>
        <TextField
          style={{ width: '150px', margin: '5px' }}
          margin='dense'
          name='inOrder'
          label='In an order'
          variant="outlined"
          select
          SelectProps={{ native: true }}
          onChange={handleChange}
          value={toolbarOptions.inOrder}
        >
          <option value='all'>All</option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </TextField>
        <div style={{ marginLeft: 'auto' }}>
          <LoadingButton loading={loading} onClick={exportData} variant='outlined'>Export Data</LoadingButton>
          <LoadingButton loading={loading} onClick={search} variant='outlined' style={{ marginLeft: '10px' }} >Search</LoadingButton>
        </div>
      </form>
    </div>
  );
};

DriverToolbar.propTypes = {
  className: PropTypes.string
};

export default DriverToolbar;
