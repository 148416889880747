import React from 'react';
import Modal from '@mui/material/Modal';
import { Typography, Box, TextField } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import toast, { Toaster } from 'react-hot-toast';
import itemsApi from 'apis/items';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const CategoriesModal = ({
  open,
  handleClose,
  state = 'create',
  category,
  onSuccess = () => null
}) => {
  const [data, setData] = React.useState(
    category && category.name ? category : { name: '', active: 'true' }
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (category && Object.keys(category).length) {
      setData(category);
    }
  }, [category]);

  const handleChange = event => {
    data[event.target.name] = event.target.value;
    setData({ ...data });
  };

  const onClose = () => {
    if (category && category.name) {
      setData(category);
    }
    handleClose();
  };

  const createCategory = async ({ name, active }) => {
    setLoading(true);
    const response = await itemsApi.CreateCategories({ name, active });

    if (response.success) {
      toast.success(response.message);
      onSuccess();
      handleClose();
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const updateCategory = async ({ id, name, active }) => {
    setLoading(true);
    const response = await itemsApi.UpdateCategories({ id, name, active });

    if (response.success) {
      toast.success(response.message);
      onSuccess();
      handleClose();
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const handleSuccess = async () => {
    if (state === 'create') {
      await createCategory({ name: data.name, active: data.active });
    }

    if (state === 'edit') {
      await updateCategory({ id: data.id, name: data.name, active: data.active });
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            {state === 'create' ? 'Create Category' : 'Edit Category'}
          </Typography>
          <TextField
            fullWidth
            label="Name"
            margin="dense"
            name="name"
            onChange={handleChange}
            required
            value={data.name}
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="dense"
            name="active"
            label="Active"
            variant="outlined"
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            value={data.active}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </TextField>
          <div style={{ marginTop: '10px' }}>
            <LoadingButton
              disabled={loading}
              loading={loading}
              onClick={handleSuccess}
              variant="outlined"
              color="success">
              {state === 'create' ? 'Create' : 'Edit'}
            </LoadingButton>
            <LoadingButton
              disabled={loading}
              loading={loading}
              onClick={onClose}
              style={{ marginLeft: '10px' }}
              variant="outlined">
              Close
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CategoriesModal;
