import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import AccountTable from '../AccountTable'
import authApi from 'apis/auth'


const useStyles = makeStyles(() => ({
  root: {},
  topSpace: {
    marginTop: '1rem'
  }
}));

const AccountDetails = props => {
  const { className, account, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    name: account ? account.name : '',
    email: account ? account.email : '',
    state: 'lagos',
    country: 'Nigeria',
    newAdminEmail: '',
    newAdminName: '',
    newAdminRole: 'admin'
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const states = [
    {
      value: 'lagos',
      label: 'Lagos'
    },
    {
      value: 'ibadan',
      label: 'Ibadan'
    },
    {
      value: 'ogun',
      label: 'Ogun'
    }
  ];


  const roles = [
    {
      value: 'admin',
      label: 'Admin'
    },
  ];

  const createAdmin = async () => {
    const { newAdminEmail, newAdminName, newAdminRole } = values;
    const response = await authApi.CreateAdmin({ email: newAdminEmail, name: newAdminName, role: newAdminRole });
    if (response.success) {
      toast.success(response.message);
      setValues({
        ...values,
        newAdminEmail: '',
        newAdminName: '',
        newAdminRole: 'admin'
      });
      fetchAccounts({ page: 1, perPage: 10 })
    } else {
      toast.error(response.message);
    }
  }

  const [accounts, setAccounts] = React.useState([])
  const [metaData, setMetaData] = useState({})

  const fetchAccounts = async ({ page = 1, perPage = 10}) => {
    const response = await authApi.GetAdmins({ page, perPage});
    setAccounts(response.data.admins)
    setMetaData(response.data.meta)
  }

  const removeAccount = async (id) => {
    const response = await authApi.RemoveAdmin({ id });
    if (response.success) {
      toast.success(response.message);
      await fetchAccounts({ page: 1, perPage: 10});
    } else {
      toast.error(response.message);
    }
  }

  React.useEffect(() => {
    fetchAccounts({ page: 1, perPage: 10})
  }, [])

  return (
    <div>
      <Toaster />
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader
            subheader="The information cannot be edited"
            title="Profile"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={account ? account.name : ''}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  required
                  value={account ? account.email : ''}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select State"
                  margin="dense"
                  name="state"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={values.state}
                  variant="outlined"
                  disabled>
                  {states.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  margin="dense"
                  name="country"
                  onChange={handleChange}
                  required
                  value={values.country}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" disabled>
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      {account && account.role === 'super_admin' && (
        <Card
          {...rest}
          className={clsx(classes.root, className, classes.topSpace)}>
          <form autoComplete="off" noValidate>
            <CardHeader subheader="Create Admin Account" title="Create Admin" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    margin="dense"
                    name="newAdminName"
                    onChange={handleChange}
                    required
                    value={values.newAdminName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="newAdminEmail"
                    onChange={handleChange}
                    required
                    value={values.newAdminEmail}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Select Role"
                    margin="dense"
                    name="newAdminRole"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.newAdminRole}
                    variant="outlined"
                  >
                    {roles.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button onClick={createAdmin} color="primary" variant="contained">
                Create
              </Button>
            </CardActions>
          </form>
          <AccountTable 
            className={clsx(classes.topSpace)} 
            accounts={accounts} 
            fetchAccounts={fetchAccounts} 
            metaData={metaData} 
            removeAccount={removeAccount}
          />
        </Card>
      )}
    </div>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
