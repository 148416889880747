import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import CategoriesModal from '../CategoriesModal';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const CategoriesTable = props => {
  const {
    className,
    categories,
    metaData,
    fetchCategories,
    toolbarOptions,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    toolbarOptions ? toolbarOptions.perPage : 10
  );
  const [page, setPage] = useState(
    toolbarOptions ? toolbarOptions.page - 1 : 0
  );

  const handleSelectAll = event => {
    const { categories } = props;

    let selectedCategories;

    if (event.target.checked) {
      selectedCategories = categories.map(user => user.id);
    } else {
      selectedCategories = [];
    }

    setSelectedCategories(selectedCategories);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCategories.indexOf(id);
    let newSelectedCategories = [];

    if (selectedIndex === -1) {
      newSelectedCategories = newSelectedCategories.concat(selectedCategories, id);
    } else if (selectedIndex === 0) {
      newSelectedCategories = newSelectedCategories.concat(selectedCategories.slice(1));
    } else if (selectedIndex === selectedCategories.length - 1) {
      newSelectedCategories = newSelectedCategories.concat(
        selectedCategories.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCategories = newSelectedCategories.concat(
        selectedCategories.slice(0, selectedIndex),
        selectedCategories.slice(selectedIndex + 1)
      );
    }

    setSelectedCategories(newSelectedCategories);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchCategories({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchCategories({ page: page + 1, perPage: event.target.value });
  };

  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({})

  const openModal = (category) => {
    setSelectedCategory(category)
    setOpen(true)
  }

  const navigateToItemsTable = id => {
    props.history.push(`/items/?categoryId=${id}`);
  };


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CategoriesModal 
        open={open} 
        state='edit'  
        handleClose={() => setOpen(false)} 
        category={selectedCategory} 
        onSuccess={() => fetchCategories({ page: 1, perPage: 25 })} 
      />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCategories.length === categories.length}
                      color="primary"
                      indeterminate={
                        selectedCategories.length > 0 &&
                        selectedCategories.length < categories.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map(category => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={category.id}
                    selected={selectedCategories.indexOf(category.id) !== -1}
                    onClick={() => navigateToItemsTable(category.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCategories.indexOf(category.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, category.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{category.id}</TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => openModal(category)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

CategoriesTable.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired
};

export default withRouter(CategoriesTable);
