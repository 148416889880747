import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import driversApi from 'apis/drivers';

import { Grid, Typography, Tooltip } from '@material-ui/core';
// import { Card } from 'components';
// import DriverProfile from './DriverProfile';
import GoogleMapReact from 'google-map-react';
import PersonIcon from '@material-ui/icons/PersonSharp';
import { DriversTable } from 'views/DriverList/components';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const DriverIcon = ({driver}) => <Tooltip placement='top' title={driver.name}><PersonIcon /></Tooltip>;

const Order = () => {
  const classes = useStyles();

  const [drivers, setDrivers] = useState([]);
  const [metaData, setMetaData] = useState({});

  const fetchDrivers = async ({ page = 1, perPage = 10 }) => {
    const res = await driversApi.GetDrivers({
      presence: 'online',
      perPage: perPage || 1000,
      page,
    });
    setDrivers(res.data.drivers);
    setMetaData(res.data.meta);
  };

  React.useEffect(() => {
    fetchDrivers({ page: 1, perPage: 1000 });
  }, []);

  const getMapBounds = (map, maps, drivers) => {
    const bounds = new maps.LatLngBounds();
  
    drivers.forEach((driver) => {
      bounds.extend(new maps.LatLng(
        driver.latitude,
        driver.longitude,
      ));
    });
    return bounds;
  };
  

  const apiIsLoaded = (map, maps, drivers) => {
    // Get bounds by our drivers
    const bounds = getMapBounds(map, maps, drivers);
    // Fit map to bounds
    map.fitBounds(bounds);
  };


  return drivers.length ? (
    <div className={classes.root}>
      <Typography variant="h3">Drivers Online </Typography>
      <div className={classes.content}>
        <Grid container>
          <section style={{ width: '100%' }}>
            {drivers.length && (
              <div
                style={{ height: '600px', width: '100%', marginTop: '20px' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: apiKey }}
                  defaultCenter={{ lat: 6.7244, lng: 3.3792 }}
                  defaultZoom={10}
                  onGoogleApiLoaded={({ map, maps }) =>  apiIsLoaded(map, maps, drivers)}
                >
                  {drivers.map(driver =>  {
                    if (driver.latitude && driver.longitude) {
                      return <DriverIcon key={driver.id} driver={driver} lat={driver.latitude} lng={driver.longitude} />
                    }
                  })}
                </GoogleMapReact>
              </div>
            )}
          </section>
          <section style={{ marginTop: '20px' }}>
            <DriversTable drivers={drivers} metaData={metaData} fetchDrivers={fetchDrivers} />
          </section>
        </Grid>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Order;
