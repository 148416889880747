import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from 'components';
import { LoadingButton } from '@mui/lab';
import CategoriesModal from '../CategoriesModal';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  flexEnd: {
    marginLeft: 'auto'
  }
}));

const CategoriesToolbar = props => {
  const { className, setToolbarOptions, toolbarOptions, search, loading, ...rest } = props;

  const classes = useStyles();

  const handleChange = (e) => {
    setToolbarOptions({...toolbarOptions, [e.target.name]: e.target.value})
  }

  const [open, setOpen] = React.useState(false);
  
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CategoriesModal 
        open={open} 
        state='create' 
        handleClose={() => setOpen(false)} 
        onSuccess={() => search({ page: 1, perPage: 25 })} 
      />
      <form className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search Categories"
          name='search'
          onChange={handleChange}
          value={toolbarOptions.search}
        />
        <LoadingButton loading={loading} onClick={search} variant='outlined' style={{ marginLeft: '10px' }} >Search</LoadingButton>
        <div style={{ marginLeft: 'auto' }}>
          <LoadingButton onClick={() => setOpen(true)} loading={loading}  variant='outlined'>Add Category</LoadingButton>
        </div>
      </form>
    </div>
  );
};

CategoriesToolbar.propTypes = {
  className: PropTypes.string
};

export default CategoriesToolbar;
