import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function CardComponent({ label = '', value = '' }) {
  return (
    <Card>
      <CardContent style={{ padding: '1rem'}}>
        <Typography><b>{label}:</b> {value}</Typography>
      </CardContent>
    </Card>
  );
}
